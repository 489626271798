<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center justify-content-center flex-row-reverse">
        <div class="col-auto ml-lg-auto">
          <div class="row align-items-center">
            <div class="col-auto">
              <ul class="list-inline list-inline-dots mb-0">
                <li class="list-inline-item">
                  <a href="#" @click.prevent="goToRoute({name:'impressum'})">Impressum</a>
                </li>
                <li class="list-inline-item">
                  <a href="#" @click.prevent="goToRoute({name:'datenschutz'})">Datenschutz</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-auto mt-3 mt-lg-0 text-center">Copyright © {{ new Date().getFullYear() }}
          <a href="https://www.jv-living.com/">jv-living.com</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name:'Footer',
    data(){
        return{
        }
    },
    created(){
    },
    methods: {
        goToRoute(params){
            this.$router.push(params)
        },
    },
}
</script>
<style>
.footer {
    background: #343a40;
    border-top: 1px solid rgba(0,40,100,.12);
    font-size: .875rem;
    padding: 1.25rem 0;
    color: #fff;
    display: block;
}
a {
    color: #fff;
}
</style>